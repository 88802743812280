import '../Assets/Css/dashboard.css';
import SendEmailIcon from '../Assets/img/icon-send-email.svg';
import FilterIcon from '../Assets/img/icon-filter.svg';
import DateRangeIcon from '../Assets/img/icon-date-range.svg';
import PDFIcon from '../Assets/img/icon-pdf.svg';
import ViewMapIcon from '../Assets/img/icon-view-map.svg';
import GeoCoordinatesIcon from '../Assets/img/icon-geo-coordinates.svg';
import { useContext, useEffect, useRef, useState } from "react";
import { eventService } from '../Services/eventService';
import toast from 'react-hot-toast';
import { UserContext } from '../Contexts/UserContext';
import { Link } from 'react-router-dom';
import jsPDF from 'jspdf';
import SendReportModal from '../Components/SendReport/SendReportModal';
import UploadKMLModal from '../Components/GailDashboard/UploadKMLModal';
import PriorityDataRequestModal from '../Components/GailDashboard/PriorityDataRequestModal';
import Moment from 'react-moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const Exceptions = [
    { label: "Others", className: "others"},
    { label: "Road", className: "roads" },
    { label: "Building", className: "buildings" },
    { label: "Tree", className: "tree" },
    { label: "Compound Wall", className: "compoundwall" },
    { label: "Heavy Vehicle", className: "heavyvehicle" },
    { label: "Soil Erosion", className: "soilerosion" },
    { label: "Digging", className: "digging" },
    { label: "Well", className: "well" }
];

const initialFilters = { exception: "", dateRange: [null, null] }

const DashboardGail = () => {
    const exceptionTableRef = useRef(null);
    const { user, sendNotification, AOI_ID, USER_KEY } = useContext(UserContext);
    const [annotations, setAnnotations] = useState([]);
    const [filteredAnnotations, setFilteredAnnotations] = useState([]);
    const [filter, setFilter] = useState(initialFilters);
    const [openSendReportModal, setOpenSendReportModal] = useState(false);
    const [showUploadKmlModal, setShowUploadKmlModal] = useState(false);
    const [showDataRequestModal, setShowDataRequestModal] = useState(false);
    const [exceptionOptions, setExceptionOptions] = useState([]);
    const userAuthId = user["auth-id"];

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    useEffect(() => {
        if (!!user) {
            getAnnotations();
        }
    }, [user]);

    useEffect(() => {
        // Exception Options
        setExceptionOptions([...new Set(annotations.map(a => a.exception.label))]);
    }, [annotations]);

    useEffect(() => {
        if (!!filter && annotations.length > 0) {
            let filteredAnnotations = [...annotations];
            if (filter.exception != "") {
                filteredAnnotations = filteredAnnotations.filter(a => a.text == filter.exception);
            }
            if (filter.dateRange[0] != null) {
                filteredAnnotations = filteredAnnotations.filter(a => filter.dateRange[0] <= new Date(a.image_date).setHours(0,0,0,0));
            }
            if (filter.dateRange[1] != null) {
                filteredAnnotations = filteredAnnotations.filter(a => filter.dateRange[1] >= new Date(a.image_date).setHours(0,0,0,0));
            }

            // Sort the filtered results in recent to old image_date order
            filteredAnnotations = filteredAnnotations.sort((a,b) =>  b.image_date - a.image_date);
            setFilteredAnnotations([...filteredAnnotations]);
        }
    }, [filter, annotations]);

    const getAnnotations = async () => {
        setAnnotations([]);
        await eventService.getTimelineV2(USER_KEY, user.region, AOI_ID)
           .then(async (events) => {
                await eventService.getAoiAnnotations(AOI_ID).then((data) => {
                    let geometryAnnotations = data && data.length > 0 ? data.filter(x => x.geometry != null) : [];
                    for (let i = 0; i < geometryAnnotations.length; i++) {
                        const ex = Exceptions.filter(e => e.label == geometryAnnotations[i].text);
                        const event = events.filter(e => e.eventGuid == geometryAnnotations[i].eventGuid)[0];
                        geometryAnnotations[i] = {
                            ...geometryAnnotations[i],
                            image_date: new Date(event.eventDate),
                            analysis_date: new Date(geometryAnnotations[i].lastUpdatedDate),
                            exception: ex.length > 0 ? ex[0] : Exceptions[0],
                            eventId: event.eventGuid
                        };
                    }
                    setAnnotations(geometryAnnotations);
                });
           });
    };

    const formatDate = (value) => {
        let date = new Date(value);
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        return day + '-' + month + '-' + year;
    };

    const exportToPDF = () => {
        const doc = new jsPDF('p', 'pt', 'a4');
        doc.setFontSize(2);
        doc.html(exceptionTableRef.current, {
            async callback(doc) {
                await doc.save('exception_data');
            },
        });
    };

    const showSendReportModal = () => {
        setOpenSendReportModal(true);
    };

    const onSendReport = (isSuccess) => {
        if (isSuccess) {
            setOpenSendReportModal(false);
        }
    };

    const sendReportEmail = (mailSubject) => {
        const search_criteria = { auth_ids: [userAuthId] }
        const subject = mailSubject ?? "Exception Data";
        const message = exceptionTableRef.current.innerHTML;

        sendNotification(search_criteria, subject, message, "transactional", onSendReport, `Report Send Successfully to ${userAuthId}`);
    };

    const onDateRangeChange = (updatedDate) => {
        setDateRange(updatedDate);
        setFilter({ ...filter, dateRange: updatedDate });
    }

    return (
        <>
            <div className="page-body">
                <div className="dashboard">
                    <div className="container">
                        <div className="card">
                            <div className="card-header">
                                <h3>Dashboard</h3>
                                <div className='btns-area'>
                                    <a onClick={() => setShowUploadKmlModal(true)} className="send-link">
                                        Upload KML
                                    </a>
                                    <a onClick={() => setShowDataRequestModal(true)} className="send-link">
                                        Priority Data Request
                                    </a>
                                    <a onClick={showSendReportModal} className="send-link">
                                        <img src={SendEmailIcon} alt="" />
                                        Send Report
                                    </a>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="filters-container">
                                    <div className="filters-left">
                                        <div className="filters-title">
                                            <img src={FilterIcon} alt="" /> Filters
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="Exception">Exception :</label>
                                            <select className="form-select" id="Exception" onChange={(e) => setFilter({ ...filter, exception: e.target.value })} value={filter.exception}>
                                                <option value="">Please Select</option>
                                                {
                                                    exceptionOptions.map(e => <option value={e} key={e}>{e}</option>)
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="Date">Date From:</label>
                                            <DatePicker
                                                selected={startDate}
                                                onChange={(startDate) => onDateRangeChange([startDate, dateRange[1]])}
                                                isClearable={true}
                                                className="form-control date"
                                            />
                                        </div>
                                        -
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="Date">To:</label>
                                            <DatePicker
                                                selected={endDate}
                                                onChange={(endDate) => onDateRangeChange([dateRange[0], endDate])}
                                                isClearable={true}
                                                className="form-control date"
                                            />
                                        </div>
                                    </div>
                                    <a href="ExceptionReport.pdf" target="_blank" className="export-link"><img src={PDFIcon} alt="" /> Export
                                        PDF</a>
                                </div>
                                 <div className="table-responsive">          
                                <table className="exception-table table-style" id="exception-table" ref={exceptionTableRef}>
                                    <tbody>
                                        <tr>
                                            <th>S.NO.</th>
                                            <th>Image Date</th>
                                            <th>Analysis Date</th>
                                            <th>Exception</th>
                                            <th>Geo-coordinates</th>
                                            <th width="150">View</th>
                                        </tr>
                                        {
                                            filteredAnnotations.length > 0 &&
                                            filteredAnnotations.map((annotation, i) =>
                                                <tr key={i+1}>
                                                    <td>{i + 1}</td>
                                                    <td><Moment date={annotation.image_date} format={"DD-MMM-YYYY"}></Moment></td>
                                                    <td><Moment date={annotation.analysis_date} format={"DD-MMM-YYYY"}></Moment></td>
                                                    <td>
                                                        <div className="exception">
                                                            <i className={`exception-type ${annotation.exception.className}`}></i>
                                                            {annotation.text}
                                                        </div>
                                                    </td>
                                                    <td className='coords-td'>
                                                        <img src={GeoCoordinatesIcon} alt="geo coords icon"></img>
                                                        <span title={annotation.geometry.coordinates.toLocaleString()}>{annotation.geometry.coordinates.toLocaleString()}</span>
                                                    </td>
                                                    <td>
                                                        <Link to={`/timelinev2/${AOI_ID}/single/${annotation.eventId}?annotation=${annotation.id}`} className="view-link"><img src={ViewMapIcon} alt="view map icon" />View on Map</Link>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        {
                                            filteredAnnotations.length == 0 &&
                                            <tr>
                                                <td colSpan="6" className='no-data-cell'>NO DATA</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                                </div>     
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {openSendReportModal &&
                <SendReportModal
                    openSendReportModal={setOpenSendReportModal}
                    sendReportEmail={sendReportEmail}
                    mailContent={exceptionTableRef.current.outerHTML}
                />
            }
            {
                showUploadKmlModal &&
                <UploadKMLModal show={setShowUploadKmlModal} />
            }
            {
                showDataRequestModal &&
                <PriorityDataRequestModal show={setShowDataRequestModal} />
            }
        </>
    )
}

export default DashboardGail;