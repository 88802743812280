import {Modal} from 'react-bootstrap';
import React, {useContext, useState} from 'react';
import toast from 'react-hot-toast';
import { adminService } from '../../Services/adminService';
import { UserContext } from '../../Contexts/UserContext';

const MemberDelete = (props) => {
    const [userName, setUserName] = useState("");
    const {user} = useContext(UserContext);

    const deleteUser = () => {
        if(userName != props.name) {
            toast.error("Please enter the username to delete");
            return;
        }
        adminService.deleteUser(props.region, props.name, user.authId).then(() => {
            toast.success(`User ${props.name} has been successfully deleted`);
            props.setShowModal(false);
            props.onDeleteUser();
        }).catch((e) => {
            toast.error(`Unable to delete the user ${props.name}. Error: ${e}`);
        });
    };

    return (
        <>
            <Modal
                show={() => props.setShowModal(true)}
                onHide={() => props.setShowModal(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-dialog"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Delete Member</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="delete-member">
                        <h6>You are about to permanently delete the member <strong>{props.name}</strong></h6>
                        <div>Type the above username to confirm 
                            <input type="text" className="form-control" value={userName} onChange={(e) => setUserName(e.target.value)} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline" data-bs-dismiss="modal" aria-label="Close" onClick={() => props.setShowModal(false)}>CANCEL</button>
                    <button type="button" className="btn btn-danger" onClick={() => deleteUser()}>DELETE</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default MemberDelete;