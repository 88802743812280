import React, {useContext, useState} from 'react';
import RoleEditIcon from '../../Assets/img/icon-role-edit.svg';
import EditIcon from '../../Assets/img/icon-edit-grey.svg';
import EyeIcon from '../../Assets/img/icon-eye-grey.svg';
import DeleteIcon from '../../Assets/img/icon-delete-grey.svg';
import SearchIcon from '../../Assets/img/icon-search-white.svg';
import TokensIcon from '../../Assets/img/icon-member-tokens.svg';
import ShowJson from './ShowJson';
import MemberDelete from './MemberDelete';
import MemberEdit from './MemberEdit';
import toast from 'react-hot-toast';
import { adminService } from '../../Services/adminService';
import { UserContext } from '../../Contexts/UserContext';
import SettingsModal from './SettingsModal';
import MemberTokens from './MemberTokens';
import { Link } from 'react-router-dom';
import { userService } from '../../Services/userService';
import ProfileModal from './ProfileModal';

const Members = () => {
    const [showEditUserModal, setShowEditUserModal] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);

    const [searchCriteria, setSearchCriteria] = useState({
            authId: "",
            region: "all",
            usecase: "all",
            subscription: "all"
        });
    const [searchResults, setSearchResults] = useState({});
    const [showViewModal, setShowViewModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditRoleModal, setShowEditRoleModal] = useState(false);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [showTokensModal, setShowTokensModal] = useState(false);
    const {user, domainConfig} = useContext(UserContext);
    const [mkey, setMkey] = useState(null);
    const [region, setRegion] = useState(null);
    const [users, setUsers] = useState([]);

    const searchUser = () => {
        userService.getAllUsers(searchCriteria.authId).then((data) => {
            console.log(data);
            setUsers(data);
        }).catch((e) => {
            toast.error("Unable to get users " + e);
        });
    };

    const changeSearch = (key, value) => {
        setSearchCriteria({
            ...searchCriteria,
            [key]: value
        });
    };

    const showUserModal = (authId, type) => {
        adminService.getUser(authId).then((data) => {
            setCurrentUser(data);
            setMkey(data.mkey);
            setRegion(data.region);
            
            if(type == "editSettings")
                setShowSettingsModal(true);
            else if(type == "showUser")
                setShowViewModal(true);
            else if(type == "updateTokens")
                setShowTokensModal(true);
        }).catch((e) => {
            toast.error("Unable to retrieve the user. Error: " + e);
            console.error(e);
        });
    };

    const showBasicModal = (authId, type) => {
        setCurrentUser({
            ...searchResults[authId],
            authId: authId,
            "auth-id": authId
        });
        setRegion(searchResults[authId].region);
        setMkey(searchResults[authId].mkey);
        if(type == "editRole")
            setShowEditRoleModal(true);
        else if(type == "deleteUser")
            setShowDeleteModal(true);
    };

    const editUser = (user) => {
        setCurrentUser(user);
        setShowEditUserModal(true);
    };

    const onUpdate = (data) => {
        searchUser();
    };

    return (
        <div className="form-content">
            <div className="form-head">
                <h3>Member Search</h3> &nbsp; &nbsp;
                <h3><a className="test" onClick={() => setShowAddModal(true)}>Add Member</a></h3>
            </div>
            <div className="cs-cockpit-section">
                <div className="search-section">
                    <div className="search-row-one">
                        <div className="form-group">
                            <label className="form-label" htmlFor="">User Name</label>
                            <input type="text" className="form-control" id="authId" placeholder=""
                                value={searchCriteria.authId} onChange={(e) => changeSearch("authId", e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="">&nbsp;</label>
                            <button type="button" className="btn btn-primary" onClick={searchUser}>
                                <img src={SearchIcon} alt="" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-style">
                    <thead>
                        <tr>
                            <th scope="col">User Name</th>
                            <th scope="col">Name</th>
                            <th scope="col">Role</th>
                            {
                                !user.isClientUser && 
                                <th scope="col">Client/Internal</th>
                            }
                            <th scope="col">City</th>
                            <th scope="col" className="action">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            users.map((suser) => {
                                return <tr>
                                            <td>{suser.userName}</td>
                                            <td>{suser.name}</td>
                                            <td>{suser.role}</td>
                                            {
                                                !user.isClientUser && 
                                                <td>{suser.isClientUser ? "Client" : "Internal"}</td>
                                            }
                                            <td>{suser.city}</td>
                                            <td className="action">
                                                <a className="test" onClick={() => editUser(suser)}><img src={EditIcon} alt="" /></a>
                                            </td>
                                            {/* <td className="action">
                                                <a className="test" onClick={() => showUserModal(authId, "editSettings")}><img src={EditIcon} alt="" /></a>
                                                <a className="test" onClick={() => showUserModal(authId, "showUser")}><img src={EyeIcon} alt="" /></a>
                                                {
                                                    (user.isAdmin || user.isCustomerService) &&
                                                    <a className="test" onClick={() => showUserModal(authId, "updateTokens")}><img src={TokensIcon} alt="" /></a>
                                                }
                                                {
                                                    user.isSuperAdmin &&
                                                    <a className="test" onClick={() => showBasicModal(authId, "deleteUser")}><img src={DeleteIcon} alt="" /></a>
                                                }
                                            </td> */}
                                        </tr>;
                            })
                        }
                    </tbody>
                </table>
            </div>
            {
                showEditUserModal && <ProfileModal setShowModal={setShowEditUserModal} user={currentUser} isAdmin={true} createUser={false} onUpdate={onUpdate} />
            }
            {
                showAddModal && <ProfileModal setShowModal={setShowAddModal} user={null} isAdmin={true} createUser={true} onUpdate={onUpdate} />
            }
            {
                showViewModal && <ShowJson setShowModal={setShowViewModal} json={currentUser} title={"Member - " + currentUser["auth-id"]}/>
            }
            {
                showDeleteModal && <MemberDelete setShowModal={setShowDeleteModal} name={currentUser.authId} region={region ?? user.region} onDeleteUser={searchUser}/>
            }
            {
                showEditRoleModal && 
                <MemberEdit setShowModal={setShowEditRoleModal} user={currentUser} region={region} mkey={mkey} refresh={searchUser}/>
            }
            {
                showSettingsModal && <SettingsModal setShowModal={setShowSettingsModal} configuration={currentUser.configuration} 
                                        mkey={currentUser.mkey} region={currentUser.region} refresh={searchUser} />
            }
            {
                showTokensModal && <MemberTokens setShowModal={setShowTokensModal} user={currentUser} refresh={searchUser}/>
            }
        </div>
    );
}

export default Members;