import { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import { aoiService } from "../Services/aoiService";
import { eventService } from "../Services/eventService";
import React from 'react';
import moment from "moment";
import SingleTimeline from "../Components/Timeline/SingleTimeline";
import TwoTimelines from "../Components/Timeline/TwoTimelines";
import { useJsApiLoader } from "@react-google-maps/api";
import TimelineBar from "../Components/Timeline/TimelineBar";
import { UserContext } from '../Contexts/UserContext';
import AddEventModal from "../Components/Timeline/AddEventModal";
import EventsDelete from "../Components/Timeline/EventsDelete";
import SendUsecaseMessageModal from "../Components/Timeline/SendUsecaseMessageModal";
import { AOIOptions } from "../Components/AOIOptions";

const appConfig = window.config;
const pollIntervalValues = [1,2,4,8,16,32,64,128,256,512,1024,1800];
const steadyStatePollInterval = pollIntervalValues[pollIntervalValues.length-1];

const DataSources = [
    {name: 'WAYBACK', resolution: 'high'},
    {name: 'GOOGLEEARTH', resolution: 'high', credit: '\u00A9Google Earth'},
    {name: 'SKYSAT', resolution: 'high', credit: '\u00A9Planet Labs'},
    {name: 'SENTINEL2', resolution: 'low'},
    {name: 'GEESentinelDW', resolution: 'low'}
]

function getPollInterval(index, t0){
    let timeDiff = Math.round((Date.now()-t0)/1000);
    return timeDiff < steadyStatePollInterval ? pollIntervalValues[index]*1000 : steadyStatePollInterval*1000;    
}

const TimelineV2 = (props) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { aoiId, timelineParamView, eventId1, eventId2 } = useParams();
    const {user, showAlert, timelineView, setTimelineView, resetTimeline, setPipelineGeometry } = useContext(UserContext);
    const [aoi, setAoi] = useState(null);
    const [currentTimeline, setCurrentTimeline] = useState(null);
    const [latestTimeline, setLatestTimeline] = useState(null);
    const [mapKey, setMapKey] = useState(0);
    const [allTimelines, setAllTimelines] = useState([]);
    const [allYears, setAllYears] = useState([]);
    const [yearToMonth, setYearToMonth] = useState([]);
    const [yearMonthToTimeline, setYearMonthToTimeline] = useState([]);
    const [fullTimelineData, setFullTimelineData] = useState([]);
    const [beforeDate, setBeforeDate] = useState(null);
    const [afterDate, setAfterDate] = useState(null);
    const [showAddEvent, setShowAddEvent] = useState(false);
    const [showDeleteEvents, setShowDeleteEvents] = useState(false);
    const [showSendUsecaseMessageModal, setShowSendUsecaseMessageModal] = useState(false);
    const [showTimelineBar, setShowTimelineBar] = useState(false);
    const [selectedAnnotationId, setSelectedAnnotationId] = useState("");
    const [center, setCenter] = useState(AOIOptions.defaultCenter);
    const [zoom, setZoom] = useState(AOIOptions.defaultZoom);
    const userKey = "081df3ef475240e1d975725850cb97d9";

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: appConfig.mapApiKey,
        libraries
    });

    useEffect(() => {
        if (searchParams.has('annotation')) {
            window.scrollTo(0, 0);
            setSelectedAnnotationId(searchParams.get('annotation'));
            searchParams.delete("annotation");
            setSearchParams(searchParams);
        }
    }, []);

    useEffect(() => {
        setTimelineView('single');
        setMapKey(mapKey+1);
        changeLatestTimeline(allTimelines[allTimelines.length - 1]);
        setCenter(AOIOptions.defaultCenter);
        setZoom(AOIOptions.defaultZoom);
    }, [resetTimeline]);

    useEffect(() => {
        const isValidView = ['single','slider','comparison'].some(view => view == timelineParamView?.trim().toLowerCase());
        if(isValidView) {
            setTimelineView(timelineParamView.trim().toLowerCase());
        }
    }, [timelineParamView]);

    useEffect(() => {
        aoiService.get(userKey, user.region, aoiId)
        .then((data) => {
            setAoi(data);
            setPipelineGeometry(data.pipelineGeometry);
        })
        .catch((error) => {
            console.error("Error in getting AOI Details", error);
            showAlert('AOI Not Found');
            navigate("/");
            return null;
        })
    }, [aoiId]);

    useEffect(() => {
        refresh();
    }, [aoiId]);

    useEffect(() => {
        if(latestTimeline != null) {
            setAfterDate(moment(new Date(latestTimeline.eventDate)).format('Do MMMM YYYY h:mm A').toString());
        }
    }, [latestTimeline]);

    useEffect(() => {
        if(currentTimeline != null) {
            setBeforeDate(moment(new Date(currentTimeline.eventDate)).format('Do MMMM YYYY h:mm A').toString());
        }
    }, [currentTimeline]);

    const refresh = () => {
        //this request needs to follow backoff logic
        eventService.getTimelineV2(userKey, user.region, aoiId).
        then((data) => {
            // filter the duplicate images:
            // obj.eventDate.split(" ")[0]  is used instead of just obj.eventDate
            // as the events contain images which are just a few minutes apart on the same date
            // Hence only the date part is extracted leaving out the timepart    
            let uniq={};
            let originalData = data;

            data = addEvent(data, originalData, eventId1);
            data = addEvent(data, originalData, eventId2);

            data.sort((a,b) => new Date(a.eventDate) - new Date(b.eventDate));

            setAllTimelines(data);
            setTimelineInMap(data, eventId1, eventId2);
            return data;
        })
        .catch((error) => {
            console.error("Error in getting TimelineV2!", error)
            return [];
        });
    };

    const addEvent = (data, originalData, eventId) => {
        if(eventId == null)
            return data;
        var event = data.filter(d => d.eventGuid == eventId);
        if(event != null && event.length > 0)
            return data;
        event = originalData.filter(d => d.eventGuid == eventId);
        if(event == null || event.length == 0)
            return data;
        data = data.filter(d => d.eventDate.split("T")[0] != event[0].eventDate.split("T")[0]);
        data.push(event[0]);
        return data;
    };

    const changeLatestTimeline = (timeline) => {
        selectLatestTimeline(allTimelines, timeline);
    };

    const selectLatestTimeline = (data, timeline) => {
        setLatestTimeline(timeline);
        if(timeline != null && data != null) {
            setCurrentTimeline(getNearestDateTimeline(data, timeline.eventDate));
        }
    }

    const findAndSetLatestTimeline = (data, eventId1, eventId2) => {
        var eventId = eventId1;
        if(timelineParamView != "single")
            eventId = eventId2;
        if(data.length >= 1) {
            if(eventId != null) {
                var eventTimeline = data.find(timeline => timeline.eventGuid == eventId);
                if(eventTimeline != null) {
                    selectLatestTimeline(data, eventTimeline);
                    return;
                }
            }
            if (!!latestTimeline) {
                if (!data.find(timeline => timeline.eventGuid == latestTimeline.eventGuid)) {
                    // If the latest timeline is not available in the newly fetched data, set the nearest date timeline
                    selectLatestTimeline(data, getNearestDateTimeline(data, latestTimeline.eventDate));
                    return;
                }
            }
            selectLatestTimeline(data, data[data.length - 1]);
        } else {
            changeLatestTimeline(null);
        }
    };

    const findAndSetCurrentTimeline = (data, eventId1, eventId2) => {
        var eventId = eventId2;
        if(timelineParamView != "single")
            eventId = eventId1;
        if(data.length >= 2) {
            if(eventId != null) {
                var eventTimeline = data.find(timeline => timeline.eventGuid == eventId);
                if(eventTimeline != null) {
                    setCurrentTimeline(eventTimeline);
                    return;
                }
            }
            if (!!currentTimeline) {
                // If the current timeline is not available in the newly fetched data, set the nearest date timeline
                if (!data.find(timeline => timeline.eventGuid == currentTimeline.eventGuid)) {
                    setCurrentTimeline(getNearestDateTimeline(data, currentTimeline.eventDate));
                    return;
                }
            }
            setCurrentTimeline(data[data.length - 2]);
        } else {
            setCurrentTimeline(null);
        }
    };

    const setTimelineInMap = (data, eventId1, eventId2) => {
        setFullTimelineData(data);
        findAndSetLatestTimeline(data, eventId1, eventId2);
        //cannot keep the below if loop nested in the above if loop because latestTimeline becomes non-null first
        //As a result, left side timeline(i.e. currentTimeline) will not have data set.
        if(eventId2 != null)
            findAndSetCurrentTimeline(data, eventId1, eventId2);

        let years = [];
        let yearMonth = {};
        let yearToMonthToTimeline = {};
        data.map((timeline) => {
            let date = new Date(timeline.eventDate);
            if(yearMonth[date.getFullYear()] == null) {
                years.push(date.getFullYear());
                yearMonth[date.getFullYear()] = [];
            }
            if(!yearMonth[date.getFullYear()].includes(date.getMonth()))
                yearMonth[date.getFullYear()].push(date.getMonth());
            if(yearToMonthToTimeline[date.getFullYear() * 100 + date.getMonth()] == null) {
                yearToMonthToTimeline[date.getFullYear() * 100 + date.getMonth()] = [];
            }
            yearToMonthToTimeline[date.getFullYear() * 100 + date.getMonth()].push(timeline);            
        });
        setAllYears(years);
        setYearToMonth(yearMonth);        
        setYearMonthToTimeline(yearToMonthToTimeline);
    };

    const getNearestDateTimeline = (data, timelineDate) => {
        let testDate = new Date(timelineDate);
        let bestDate = 0;
        let bestDiff = (new Date(0,0,0)).valueOf();
        let currDiff = 0;

        for(let i = 0; i < data.length; ++i){
            currDiff = new Date(data[i].eventDate) - testDate;
            if(currDiff < 0 && currDiff > bestDiff){
                bestDate = i;
                bestDiff = currDiff;
            }   
        }
        return data[bestDate];
    };

    const selectTimeline = (timelineType, timeline, selectedMonth = null, selectedYear = null) => {
        if (selectedYear != null) {
            if (selectedMonth == null) {
                selectedMonth = yearToMonth[selectedYear][0];
            }
            const timelineYearMonth = getYearMonthFromTimeline(timelineType == "latest" ? latestTimeline : currentTimeline);
            const selectedYearMonth = selectedYear * 100 + selectedMonth;
            const timelinesInSelectedYearMonth = yearMonthToTimeline[selectedYearMonth];
            if (timelineYearMonth > selectedYearMonth) {
                // User going back in timeline, Get the latest timeline of the selected month 
                timeline = timelinesInSelectedYearMonth[timelinesInSelectedYearMonth.length - 1];                
            }
            else if (timelineYearMonth < selectedYearMonth) {
                // User going in future in timeline, Get the earliest timeline of the selected month
                timeline = timelinesInSelectedYearMonth[0];
            }
            else {
                // User selected the same month, Don't do anything
                return;
            }
        }
        if (!!timeline && !!timelineType) {
            if (timelineType == "current")
                setCurrentTimeline(timeline);
            else if (timelineType == "latest") {
                changeLatestTimeline(timeline); 
            }
        }
        //changeURL(view);
    };

    // const changeURL = (view) => {
    //     if(view == "single") {
    //         if(latestTimeline != null)
    //             navigate(`/timelinev2/${aoiId}/single/${latestTimeline.eventGuid}`);
    //     } else {
    //         if(latestTimeline != null && currentTimeline != null)
    //             navigate(`/timelinev2/${aoiId}/${view}/${currentTimeline.eventGuid}/${latestTimeline.eventGuid}`);
    //     }
    // };

    const getYearMonthFromTimeline = (timeline) => {
        const timelineDate = new Date(timeline.eventDate);
        return timelineDate.getFullYear() * 100 + timelineDate.getMonth();
    };

    const changeSelectedAnnotationId = (annotationId) => {
        setSelectedAnnotationId(annotationId);
        if(annotationId != "" && annotationId != null) {
            setMapKey(mapKey+1);
        }
    };

    return (
        <div className="page-body timeline-view">
            <div className={`timeline-compare ${timelineView == "single" && 'full-view'}`}>
                <>
                    {/* <div className="timeline-options">
                    </div> */}
                        {/* <a className={view == "single" ? "active" : ""} onClick={() => changeView("single")}>Single</a>
                        <a className={view == "slider" ? "active" : ""} onClick={() => changeView("slider")}>Slider</a>
                        <a className={view == "comparison" ? "active" : ""} onClick={() => changeView("comparison")}>Compare</a>
                        <a className="da-link photo-upload" onClick={() => setShowAddEvent(true)}>Add Event<i></i></a> */}
                </> 
                {
                    !!showTimelineBar && timelineView == "single" &&
                    <div className="timeline-year-month right full-view">
                        <TimelineBar 
                          years={allYears} 
                          yearToMonth={yearToMonth} 
                          yearMonthToTimeline={yearMonthToTimeline} 
                          fullTimelineData={fullTimelineData} 
                          initialTimeline={latestTimeline}
                          selectTimeline={selectTimeline} 
                          timelineType="latest"
                        />
                    </div>
                }
                {
                    !!showTimelineBar && timelineView != "single" && 
                    <>
                        {/* <div className={"separator-" + view}></div> */}
                        <div className={"timeline-year-month left " + timelineView}>
                            <TimelineBar years={allYears} yearToMonth={yearToMonth} yearMonthToTimeline={yearMonthToTimeline} 
                                fullTimelineData={fullTimelineData} initialTimeline={currentTimeline} selectTimeline={selectTimeline} timelineType="current"/>
                        </div>
                        <div className={"timeline-year-month right " + timelineView}>
                            <TimelineBar years={allYears} yearToMonth={yearToMonth} yearMonthToTimeline={yearMonthToTimeline} 
                                fullTimelineData={fullTimelineData} initialTimeline={latestTimeline} selectTimeline={selectTimeline} timelineType="latest"/>
                        </div>
                    </>
                }
            </div>
            <div className='timeline-map-container'>
                {
                    timelineView != "single" && 
                    <>
                        <div className="before-date">
                            <div>{beforeDate}</div>
                        </div>
                        {
                            currentTimeline && 
                            currentTimeline.imageCredits &&
                            <div className="before-credit">
                                Image Credits:&nbsp; 
                                { currentTimeline.imageCredits}
                            </div>
                        }
                    </>
                }
                <div className='after-date'>
                    <div>{afterDate}</div>
                </div>
                <a className="timeline-toggle" onClick={() => setShowTimelineBar(!showTimelineBar)}></a>
                {
                    latestTimeline && 
                    latestTimeline.imageCredits &&
                    <div className="after-credit">
                        Image Credits:&nbsp;
                        {latestTimeline.imageCredits}
                    </div>
                }
                <div className='timeline-map'>
                    {
                        isLoaded &&
                        (
                            timelineView == "single" 
                            ? <SingleTimeline aoi={aoi} timeline={latestTimeline} showZoom={true} drawInfo={true} refresh={refresh} showToggleOptions={true} view={timelineView} selectedAnnotationId={selectedAnnotationId} setSelectedAnnotationId={changeSelectedAnnotationId} key={mapKey} center={center} setCenter={setCenter} zoom={zoom} setZoom={setZoom} />
                            : <TwoTimelines aoi={aoi} currentTimeline={currentTimeline} latestTimeline={latestTimeline} splitPanel={timelineView == "comparison"} key={mapKey} drawInfo={true} refresh={refresh} view={timelineView} selectedAnnotationId={selectedAnnotationId} setSelectedAnnotationId={changeSelectedAnnotationId} center={center} setCenter={setCenter} zoom={zoom} setZoom={setZoom} />
                        )
                    }
                </div>
            </div>
            {
                showAddEvent && <AddEventModal show={(show) => setShowAddEvent(show)} aoi={aoi} usecase="AoIRGBImageV2" refresh={refresh}></AddEventModal>
            }
            {
                showDeleteEvents && <EventsDelete show={(show) => setShowDeleteEvents(show)} aoi={aoi} usecase="AoIRGBImageV2" refresh={refresh} />
            }
            {
                showSendUsecaseMessageModal && <SendUsecaseMessageModal setShowSendUsecaseMessageModal={setShowSendUsecaseMessageModal} 
                    aoi={aoi} url={`/timelinev2/${aoiId}/single/${latestTimeline.eventGuid}`} comparisonUrl={`/timelinev2/${aoiId}/comparison/${currentTimeline.eventGuid}/${latestTimeline.eventGuid}`}/>
            }
        </div>
    );
}

export default TimelineV2;