import React, {useState, useEffect} from 'react';
import toast from "react-hot-toast";
import { eventService } from "../../../Services/eventService";
import Moment from "react-moment";
import ReactDatePicker from 'react-datepicker';


const EditEvent = (props) => {
    const [editEvent, setEditEvent] = useState(props.event);
    const [paths, setPaths] = useState({});

    useEffect(() => {
        console.log(props);
        let paths = {};
        props.event.images.map((image) => {
            paths[image.eventImageId] = image.imagePath;
        });
        setPaths(paths);
    }, [props.event]);

    const changeImagePath = (imageId, path) => {
        let updatedPaths = {...paths, [imageId]: path};
        setPaths(updatedPaths);
    };

    const getUpdatedEvent = () => {
        let updatedEvent = {...editEvent};
        updatedEvent.images = [];
        editEvent.images.map((image) => {
            updatedEvent.images.push({...image, imagePath: paths[image.eventImageId] });
        });
        return updatedEvent;
    };

    const save = () => {
        let updatedEvent = getUpdatedEvent();
        if(props.forCreate) 
            createEvent(updatedEvent);
        else
            saveEditEvent(updatedEvent);
    };

    const saveEditEvent = (updatedEvent) => {
        eventService.updateEvent(editEvent.eventGuid, updatedEvent)
            .then((data) => {
                toast.success("Updated the Event Successfully");
                props.onSave();
            });
    };

    const createEvent = (updatedEvent) => {
        eventService.createEvent(props.aoi.aoiGuid, updatedEvent)
            .then((data) => {
                toast.success("Created the Event Successfully");
                props.onSave();
            });
    };

    return (
        <div className="form-content">
            <div>
                {
                    !!props.forCreate 
                    ? <h3>Create Event for {props.aoi?.name}</h3>
                    : <h3>Edit Event - <Moment date={editEvent.eventDate} format={"DD-MMMM-YYYY"}></Moment></h3>
                }
                <div>
                    {
                        !!props.forCreate &&
                        <div>
                            Event Date <ReactDatePicker selected={editEvent.eventDate} onChange={(date) => setEditEvent({...editEvent, eventDate: date})} dateFormat="P" />
                        </div>
                    }
                    <div>Is Internal Event <input type="checkbox" checked={editEvent.isInternal} onChange={(e) => setEditEvent({...editEvent, isInternal: e.target.checked})}/></div>
                    <div>Image Credits <input type="text" value={editEvent.imageCredits} onChange={(e) => setEditEvent({...editEvent, imageCredits: e.target.value})}/></div>
                </div>
                {
                    editEvent.images.map((image) => {
                        return <div>
                                    <div>Name: {image.name}</div>
                                    <div>Maxx: {image.maxx}</div>
                                    <div>Maxy: {image.maxy}</div>
                                    <div>Minx: {image.minx}</div>
                                    <div>Miny: {image.miny}</div>
                                    <div>
                                        <input type="text" value={paths[image.eventImageId]} className="form_input" style={{width: "500px"}} 
                                            onChange={(e) => changeImagePath(image.eventImageId, e.target.value)}/>
                                    </div>
                                </div>;
                    })
                }
                <button type="button" className="btn btn-outline" onClick={() => props.cancel()}>CANCEL</button>
                <button type="button" className='btn btn-primary ml-auto submit-btn' onClick={()=>save()}>SAVE</button>
            </div>
        </div>
    );
}

export default EditEvent;