import { fetchWrapper } from '../Helpers/fetchWrapper';
import { apiService } from './apiService';

const getAois = () => {
    return fetchWrapper.get(`${apiService.getApiUrl()}/aoi/all`);
};

const getAoiParts = () => {
    return fetchWrapper.get(`${apiService.getApiUrl()}/aoi/aoiparts`);
};

const validateGeometry = (region, usecase, geometry) => {
    // The API expects the body in list of geometries format.
    const body = {
        "usecase_name": usecase,
        "geometries": [{
            "type": "Polygon",
            "coordinates": geometry
        }]
    };
    return fetchWrapper.post(`${apiService.getApiBaseUrl(region)}/aoi/validate-geometry`, body);
};

const getPricingForServices = (mkey, region, data) => {
    return fetchWrapper.post(`${apiService.getApiBaseUrl(region)}/billing/${mkey}/pricing-query2`, data);
};

const subscribeUsecase = (mkey, region, member_aoi_id, usecaseName, data) => {
    const postData = (data == null ? {} : data);
    return fetchWrapper.post(`${apiService.getApiBaseUrl(region)}/billing/${mkey}/aoi/${member_aoi_id}/usecase/${usecaseName}/subscribe`, postData);
};

const unsubscribeUsecase = (mkey, region, member_aoi_id, usecaseName) => {
    return fetchWrapper.post(`${apiService.getApiBaseUrl(region)}/billing/${mkey}/aoi/${member_aoi_id}/usecase/${usecaseName}/unsubscribe`, {});
};

const queryAOIs = (mkey, region, extIds) => {
    return fetchWrapper.get(`${apiService.getApiUrl()}/aoi/all`);
};

export const aoiService = {
    getAois,
    getAoiParts,
    getAll,
    get,
    getGroups,
    create,
    update,
    subscribeUsecase,
    unsubscribeUsecase,
    attributes,
    deleteAoi,
    getAOITimezone,
    queryAOIs,
    validateGeometry,
    getPricingForServices
};

function getAll(mkey, region) {
    return fetchWrapper.get(`${apiService.getMemberApiBaseUrl(region)}/${mkey}?include_aois=true&include_groups=true`);
}

function getGroups(mkey, region) {
    return fetchWrapper.get(`${apiService.getMemberApiBaseUrl(region)}/${mkey}?include_groups=true`);
}

function get(mkey, region, member_aoi_id) {
    return fetchWrapper.get(`${apiService.getMemberApiBaseUrl(region)}/${mkey}/aoi/${member_aoi_id}`);
}

// function queryAOIs(mkey, region, extIds) {
//     return fetchWrapper.post(`${apiService.getMemberApiBaseUrl(region)}/${mkey}/query`, {
//         "include_aois": true,
//         "include_groups": true,
//         "cached_aoi_ext_ids": extIds
//     });
// }

function deleteAoi(mkey, region, member_aoi_id) {
    return fetchWrapper.deleteRequest(`${apiService.getMemberApiBaseUrl(region)}/${mkey}/aoi/${member_aoi_id}`);
}

function create(mkey, region, aoi) {
    return fetchWrapper.post(`${apiService.getMemberApiBaseUrl(region)}/${mkey}/aoi`, aoi);
}

function update(mkey, region, member_aoi_id, aoi) {
    return fetchWrapper.put(`${apiService.getMemberApiBaseUrl(region)}/${mkey}/aoi/${member_aoi_id}`, aoi);
}

function attributes(mkey, region, member_aoi_id, attributes) {
    return fetchWrapper.put(`${apiService.getMemberApiBaseUrl(region)}/${mkey}/aoi/${member_aoi_id}/attributes`, attributes);
}

function getAOITimezone(lat, lng, datetime) {
    const timestamp = new Date(datetime).getTime()/1000;
    const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${window.config.mapApiKey}`;
    return fetchWrapper.get(url, null, false);
}
